(function ($) {

    // ---------- APP START
    var App = {

        config: {
            scrollOffset: 100
        }, 

        init: function () {
            $('.js-participate__item').on("mouseover", 
                function() {
                    $('.js-participate__road').attr('src', '/public/images/web1/maps/' + $(this).data("map") + '.svg').css('opacity', 1);
                }
            )
            .on("mouseleave",
                function () {
                    $('.js-participate__road').css('opacity', 0);
                }
            );

            // Init SVG polyfill
            if (typeof (svg4everybody) !== 'undefined') svg4everybody();

            // Init object-fill polyfill
            if (typeof (objectFitImages) !== 'undefined') objectFitImages();



            //Back to home link
            $('.back').on('click', function (e) {
                e.preventDefault();
                window.location = '/';
            });

            // Remove focus state when clicking on a button with a mouse
            $(':not(form) button, a').on('click', function (e) {
                if (e.clientX && e.clientY)
                    $(this).blur();
            });

            $('a[href^="#"]:not(a[href="#tac"])').on('click', function (e) {
                e.preventDefault();
                var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');
                if ($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
                    App.scroll(scrollTo, $(this).data('scroll-offset'));
            });

            if ($('[data-scroll]').length) {
                App.scroll($('[data-scroll]').data('scroll'));
            }

            if ($('.header'.length)) $('.header').header();

            App.initClickableDivs();
            if ($('.story__slider'.length)) App.timelineSlides();
            if ($('.slider'.length)) App.slickSlider();
            if ($('.scrollbar__search'.length)) App.scrollBarSearch();
            if ($('.header__pageup'.length)) App.pageUp();
            if ($('.featured__cont'.length)) App.slidingCont();
            if ($('.l-scrollbar').length) App.initScrollBar();
            if ($('.show-more').length) App.initShowMore();
            if ($('.js-submit-btn').length) App.initFormSubmit();
            if ($('.parea-aside__toggler').length) App.initAsideToggler();
            if ($('.partners__inner').length) App.initDropdown();
            if ($('.rankings__item').length) App.rankingsToggle();
            if ($('.rankings__round').length) App.rankingsRound();
            if ($('.rankings__info').length) App.rankingsGetInfo();
            if ($('.stats__bar').length) App.statsBar();
            if ($('.newswire').length) App.initNewswire();
            if ($('.latest-news').length) App.news.initLatestNews();
            if ($('.popin-form-btn').length) App.mfp.initPopinForm();
            if ($('.js-popup').length) App.mfp.initPopup();
            if ($('.js-wmpopin').length) App.mfp.initWMPopup();
            if ($('.mfp-single-link'.length)) App.mfp.initMfpImage();
            if ($('.albums__gallery').length > 0) App.mfp.initAlbumsGallery();
            if ($('.photo-thumb__gallery').length > 0) App.mfp.initPhotosGallery();
            if ($('#home-popup').length) App.mfp.initHomePopup();
            if ($('.main-video-playlist').length > 0) App.videos.initPlaylist();
            if ($('.index-filters__btn').length) App.indexFiltersToggle();
            if ($('.audios-index').length) App.initAudioPlayer();
            if ($('.videos-skipper').length) App.mfp.initVideosSkipper();
            if ($('.audios-skipper').length) App.initAudioSkipper();

            if ($('.qnl_form').length) App.forms.initQuickNewsletterSubscription();
            if ($('#par_form').length) {
                App.forms.initNestedDisplays($(window.par_nested_displays));
                App.forms.initNestedSelects($(window.par_nested_selects));
                App.forms.initShowPass();
            }

            // used to detect real height available on mobile
            if ($(window).width() <= 480) {
                document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px');
            }
            if ($(window).width() <= 1023) {
            }

            if ($('.days-schedule__dates').length > 0) App.initDaysSchedule();

            if ($('.countdown').length) {
                var d = new Date();
                var _rand = Math.floor(d.getUTCFullYear() + '' + d.getUTCMonth() + '' + d.getUTCDate() + '' + d.getUTCHours() + '' + d.getUTCMinutes());
                $.ajax({
                    //url: '/' + $('html').attr('lang') + '/get-time?n='+_rand,
                    url: '/gettime?n=' + _rand,
                    success: function (html) {
                        $('.countdown').each(function () {
                            $(this).countdown({
                                timestampStart: parseInt(html),
                                timestampEnd: parseInt($(this).attr('data-date')),
                                classes: {
                                    sign: 'countdown__value.m--sign',
                                    days: 'countdown__value.m--days',
                                    hours: 'countdown__value.m--hours',
                                    minutes: 'countdown__value.m--minutes',
                                    seconds: 'countdown__value.m--seconds'
                                },
                                mode: $(this).attr('data-mode') !== undefined ? $(this).attr('data-mode') : 'default'
                            });
                        });
                    }
                });
            }

            /* Specific Skippers aera */
            if ($('.skiparea-registration__form').length && typeof Dropzone != 'undefined') {
                App.dropZone();
            }
            if ($('.skiparea-registration__form').length) App.skipperArea.initForms();
            if ($('#skiparea_add_sponsor').length) App.skipperArea.initAddSponsor();

            if (window.location.hash) App.initHash();

        },

        load: function () {
            App.extendHeader();

            var currentlang = $('html').attr('lang') || 'fr';

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/sw' + currentlang + '.js', { scope: "/" })
                    .then(function (registration) { console.log('Service worker ready'); registration.update(); })
                    .catch(function (error) { console.log('Registration failed with ' + error); });
            }
            else {
                console.log('Service worker unavailable');
            }

            if ($(window).width() >= 960) {
                App.config.scrollOffset = 155;
            }

            // if (window.location.hash && $(window.location.hash).length) App.scroll(window.location.hash);
        },

        initAsideToggler: function () {
            var toggler = $('.parea-aside__toggler'),
                aside = $('.parea-aside__toggler').closest('.parea-aside');

            toggler.on('click', function (e) {
                if (aside.hasClass('is-open')) {
                    aside.removeClass('is-open');
                } else {
                    aside.addClass('is-open');
                }
            });

            enquire.register('screen and (min-width: 960px)', {
                match: function () {
                    aside.removeClass('is-open');
                },
                unmatch: function () {
                }
            });
        },

        initFormSubmit: function () {
            $('.js-submit-btn').on('click', function (e) {
                e.preventDefault();
                $(this).data('submit-target').click();
            });
        },

        // Make div (with eventually child links) clickable
        initClickableDivs: function (context) {
            var clickableDivs;

            if (context === undefined) {
                clickableDivs = $('[data-href]');
            } else if (context.data('href')) {
                clickableDivs = context;
            } else {
                clickableDivs = context.find('[data-href]');
            }

            var enable = true;

            // Data-href
            clickableDivs.click(function () {
                if (enable) {
                    if ($(this).data('href-target') == '_blank') {
                        window.open($(this).data('href'), '_blank');
                    } else {
                        document.location.href = $(this).data('href');
                    }
                }
            }).find('a').click(function (e) {
                if (enable) {
                    e.stopPropagation();
                }
            });
        },

        extendHeader: function () {
            $(function () {
                $('#header').css({
                    '--headerBlockLastColor': $('.js-header__top > *:last-child').css('background-color'),
                });
            });
        
        },

        initTAC: function () {
            tarteaucitronForceCDN = '/public/js/web1/tac/';
            tarteaucitronForceLanguage = $('html').attr('lang') || 'en';

            // Include TAC script
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = tarteaucitronForceCDN + 'tarteaucitron.js';
            $('head').append(s);

            // Initialize TAC
            var privacyUrl = '/...';
            var cookiesUrl = '/...';

            tarteaucitron.init({
                'privacyUrl': privacyUrl,
                'hashtag': '#tac',
                'cookieName': 'tac',
                'orientation': 'middle',
                'showAlertSmall': false,
                'cookieslist': true,
                'adblocker': false,
                'AcceptAllCta': true,
                'highPrivacy': true,
                'handleBrowserDNTRequest': false,
                'removeCredit': true,
                'moreInfoLink': true,
                'useExternalCss': true,
                'readmoreLink': cookiesUrl
            });

            tarteaucitron.job = tarteaucitron.job || [];
            // Google Analytics
            //tarteaucitron.user.analyticsUa = 'UA-15958030-3';
            // tarteaucitron.user.analyticsMore = function () {};
            // tarteaucitron.job.push('analytics');
						// GTM
						tarteaucitron.user.googletagmanagerId = 'GTM-PQ7HWPV';
						tarteaucitron.job.push('googletagmanager');
        },

        // Scroll
        scroll: function (element, offset) {
            offset = offset === undefined ? App.config.scrollOffset : offset;
            $('html, body').animate({ scrollTop: $(element).offset().top - offset }, 'slow');
        },

        // Sliding mobile stuff
        slidingCont: function () {
            $slidingBtn = $('.featured__cont:not(.m--nojs)');
            $slidingStuff = $('.featured__latest');
            $windowWidth = $(window).width();

            function slideresp() {
                $slidingBtn.click(function () {
                    $slidingStuff.toggleClass('opened');
                });
            }

            $(window).resize(function () {
                if ($windowWidth < 960) {
                    slideresp();
                }
            });

            if ($windowWidth < 960) {
                slideresp();
            }
        },

        scrollBarSearch: function () {
            var $search = $(".scrollbar__search");
            var $searchBtn = $search.find("button");
            $searchBtn.click(function () {
                $search.toggleClass("active");
            });
        },

        pageUp: function () {
            $(window).scroll(function () {
                var $pageBottom = $(this).scrollTop() + $(this).height() - $('.header').height();
                var $footerTop = $('body').height() - $('.footer__bottom').height();
                var $pageUp = $('.header__pageup');
                if ($pageBottom >= $footerTop) {
                    $pageUp.addClass('m--sticky');
                } else {
                    $pageUp.removeClass('m--sticky');
                }
            });
        },

        timelineSlides: function() {
            $timelineSlides = new Swiper('.story__slider', {
                slidesPerView: "auto",
                spaceBetween: 8,
                centerInsufficientSlides: true,
                pauseOnMouseEnter: true,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                },
            });
        },

        // Slick slider
        slickSlider: function () {
            $('.slick').each(function (i) {

                var $slider = $(this);
                var $sliderSlides = $slider.find('.slider__slides');
                var $newsSlides = $slider.find('.home-race__news__slides');
                var $skipperSlides = $slider.find('.skippers__slides');
                var $entrantsSlides = $slider.find('.entrants__slides');
                // var $timelineSlides = $slider.find('.story__slides');
                var $latestSlides = $slider.find('.news__slides');
                var $moreSlides = $slider.find('.news__slides').filter('.m--more');
                var $pictureSlides = $slider.find('.pictures-slider__slides');
                var $prizeSlides = $slider.find('.prizes-slider__slides');
                var $multimediaSlides = $slider.find('.media-slider__slides');


                $(window).resize(function () {
                    $skipperSlides.slick('resize');
                    $entrantsSlides.slick('resize');
                    $newsSlides.slick('resize');
                    $sliderSlides.slick('resize');
                    // $timelineSlides.slick('resize');
                    $latestSlides.slick('resize');
                    $pictureSlides.slick('resize');
                    $prizeSlides.slick('resize');
                    $multimediaSlides.slick('resize');
                });

                i++;
                $slider.addClass('slickslider-' + i);

                if ($slider.hasClass('skippers__carousel')) {
                    var total = $('.skippers__carousel img').length,
                        rand = Math.floor(Math.random() * total);
                    $skipperSlides.slick({
                        autoplay: true,
                        nextArrow: $slider.find('.arrow.m--next'),
                        prevArrow: $slider.find('.arrow.m--prev'),
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        initialSlide: rand,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    });
                }

                else if ($slider.hasClass('entrants__carousel')) {
                    var total2 = $('.entrants__carousel img').length,
                        rand2 = Math.floor(Math.random() * total2);
                    $entrantsSlides.slick({
                        autoplay: true,
                        nextArrow: $slider.find('.arrow.m--next'),
                        prevArrow: $slider.find('.arrow.m--prev'),
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        initialSlide: rand2,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    });
                }

                else if (($slider).hasClass('featured__slider')) {
                    $sliderSlides.slick({
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 4000,
                        fade: true,
                        dots: true,
                        arrows: false,
                        pauseOnHover: false
                    });
                }

                else if (($slider).hasClass('home-race__news__slider')) {
                    $newsSlides.slick({
                        infinite: false,
                        autoplay: true,
                        autoplaySpeed: 4000,
                        arrows: false,
                        slidesToShow: 5,
                        responsive: [
                            {
                                breakpoint: 1600,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 4,
                                }
                            },
                            {
                                breakpoint: 1280,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3
                                }
                            },
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 960,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    });
                }

                else if (($slider).hasClass('news__carousel')) {
                    $latestSlides.slick({
                        // autoplay: true,
                        nextArrow: $slider.find('.arrow.m--next'),
                        prevArrow: $slider.find('.arrow.m--prev'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        mobileFirst: true,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: { slidesToShow: 3 }
                            }
                        ]
                    });
                }

                else if (($slider).hasClass('mobile__carousel')) {
                    $latestSlides.slick({
                        // autoplay: true,
                        nextArrow: $slider.find('.arrow.m--next'),
                        prevArrow: $slider.find('.arrow.m--prev'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        mobileFirst: true,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: "unslick"
                            }
                        ]
                    });
                }

                else if (($slider).hasClass('more__carousel')) {
                    $moreSlides.slick({
                        autoplay: true,
                        nextArrow: $slider.find('.arrow.m--next'),
                        prevArrow: $slider.find('.arrow.m--prev'),
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }

                else if (($slider).hasClass('pictures-slider')) {
                    $pictureSlides.slick({
                        infinite: false,
                        fade: true,
                        nextArrow: $slider.find('.arrow.m--next'),
                        prevArrow: $slider.find('.arrow.m--prev'),
                    });

                    $pictureSlides.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                        var $counter = $slider.find('.pictures-slider__counter');
                        var percentage = 100 / (slick.slideCount - 1);
                        var percentageToRemove = percentage * nextSlide;
                        var counterWidth = $counter.width() - (($counter.width() - 150) * percentageToRemove / 100);

                        $counter.find('p').css('width', counterWidth + 'px');
                        $counter.find('span').html(nextSlide + 1);
                    });
                }

                else if (($slider).hasClass('prizes-slider')) {
                    $prizeSlides.slick({
											infinite: false,
											// variableWidth: true,
											slidesToShow: 3.2,
											slidesToScroll: 3,
											nextArrow: $slider.find('.arrow.m--next'),
											prevArrow: $slider.find('.arrow.m--prev'),
											responsive: [
												{
													breakpoint: 767,
													settings: {
														slidesToShow: 2,
														slidesToScroll: 2
													}
												},
												{
													breakpoint: 480,
													settings: {
														slidesToShow: 1,
														slidesToScroll: 1
													}
												}
											]
                    });
                }

                else if (($slider).hasClass('media-slider')) {
                    $multimediaSlides.slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        mobileFirst: true,
                        arrows: false,
                        variableWidth: true,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: "unslick"
                            }
                        ]
                    });
                }

                else {
                    $sliderSlides.slick({
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 4000,
                        nextArrow: $slider.find('.arrow.m--next'),
                        prevArrow: $slider.find('.arrow.m--prev'),
                        pauseOnHover: false
                    });
                }

            });

            /* Add article title to slider dots */
            var $sliderDot = $('.featured__slider').find('.slick-dots li');
            var $sliderTitle = $('.featured__slider').find('.slick-slide:not(.slick-cloned) .slider__slide__title');
            var $sliderTable = [];

            $sliderTitle.each(function () {
                $sliderTable.push($(this).text());
            });

            for (i = 0; i < $sliderDot.length; i++) {
                $sliderDot[i].append($sliderTable[i]);
            }

        },

        initNewswire: function () {
            $('.newswire__list').marquee({
                duration: 12500,
                duplicated: true,
                startVisible: true,
                gap: 40
            });
        },

        initScrollBar: function () {
            $('.l-scrollbar').each(function () {
                if ($(window).width() > 768 || ($(window).width() < 768 && !$(this).hasClass('rankings__scroll'))) {
                    var ps = new PerfectScrollbar(this, {
                        suppressScrollX: true
                    });
                }
            });
        },

        initShowMore: function () {
            $('.show-more').each(function () {
                var target = $(this).data('show-more-target');
                $(this).on('click', function () {
                    $(target).addClass('is-opened');
                    $(this).hide();
                });
            });
        },

        initHash: function () {
            // Open popup when its id is in URL fragment (after #pop-)
            // Removes fragment from URL when popup is closed
            if (window.location.hash.substr(0, 5) == '#pop-') {
                $.magnificPopup.open({
                    items: {
                        src: '#' + window.location.hash.substr(5)
                    },
                    callbacks: {
                        close: function () {
                            history.replaceState(null, '', window.location.pathname);
                        }
                    }
                });
            }

            if ($('.albums__gallery')) {
                $(window.location.hash).click();
            }
        },

        initDropdown: function () {

            $('.partners__inner').on('click', function () {
                if ($(window).width() > 768 && $(window).width() <= 1024) {
                    if ($(this).hasClass('is-open')) {
                        $(this).removeClass('is-open');
                    } else {
                        $('.partners__inner').removeClass('is-open');
                        $(this).addClass('is-open');
                    }
                } else if ($(window).width() <= 768) {
                    $(this).toggleClass('is-open');
                }
            });

        },

        mfp: {

            initHomePopup: function () {
                function getCookie(cname) {
                    var name = cname + "=";
                    var ca = document.cookie.split(';');
                    for (var i = 0; i < ca.length; i++) {
                        var c = ca[i];
                        while (c.charAt(0) == ' ') {
                            c = c.substring(1);
                        }
                        if (c.indexOf(name) == 0) {
                            return "cookie is set";
                        }
                    }
                    return false;
                }

                enquire.register('screen and (max-width: 480px)', {
                    match: function () {
                        if (!getCookie('popup')) {
                            $.magnificPopup.open({
                                items: {
                                    src: '#home-popup'
                                }
                            });
                            document.cookie = 'popup=1;max-age=' + (3600 * 24);
                        }
                    },
                    unmatch: function () {
                        // toggler.after(topBtns.removeClass('l-sidebar'));
                    }
                });
            },

            initPopup: function () {
                $('.js-popup').magnificPopup({});
            },

            initWMPopup: function () {
                $('.js-wmpopin').magnificPopup({
                    type: 'iframe',
                    mainClass: 'mfp-popin-map',
                    fixedContentPos: true
                });
            },

            initMfpImage: function () {
                $('.mfp-single-link').magnificPopup({
                    type: 'image',
                    mainClass: 'mfp-img-mobile',
                    fixedContentPos: true,
                    image: {
                        titleSrc: function (item) {
                            var credit = item.el.attr('data-credit'),
                                title = item.el.attr('title');

                            if (credit) {
                                title += '<small>' + item.el.attr('data-credit') + '</small>';
                            }

                            return title;
                        }
                    },
                    callbacks: {
                        change: App.getImgSharer
                    }
                });
            },

            initPopinForm: function () {
                var popinForm;

                $('.popin-form-btn').magnificPopup({
                    type: 'ajax',
                    preloader: false,
                    mainClass: 'mfp-popin-form',
                    callbacks: {
                        parseAjax: function (mfpResponse) {
                            mfpResponse.data = parseFormContent(mfpResponse.data);
                        },
                        ajaxContentAdded: function () {
                            popinForm = this;
                            $(popinForm.content).find('input').first().focus();

                            var breakAjax = this.currItem.el.attr('data-break-ajax');

                            if (breakAjax !== 'true')
                                bindContent();
                        },
                        open: function () {
                            var close = $(this.content).find('.mfp-close');

                            close.appendTo(close.closest('.mfp-container'));
                        }
                    }
                });

                function parseFormContent(html) {
                    return $(html).find('#popin_form');
                }

                function bindContent() {
                    var form = popinForm.content.find('form'),
                        submit = form.find('.arrow-link__submit');
                }

                function appendContent(html) {
                    var close = $(popinForm.content).find('.mfp-close').detach(),
                        content = parseFormContent(html);

                    popinForm.content.parent().html(content);
                    popinForm.content = content;
                    close.appendTo(popinForm.content);

                    bindContent();
                }
            },

            initPhotosGallery: function () {
                $('.photo-thumb__gallery').each(function (index, element) {
                    $(element).magnificPopup({
                        delegate: '.photo-thumb',
                        type: 'image',
                        mainClass: 'mfp-pictures',
                        fixedContentPos: true,
                        gallery: {
                            enabled: true,
                            navigateByImgClick: true,
                            preload: [0, 1],
                            tCounter: ''
                        },
                        image: {
                            titleSrc: function (item) {
                                var credit = item.el.attr('data-credit'),
                                    title = item.el.attr('title');
                                if (credit) {
                                    title += '<small>' + item.el.attr('data-credit') + '</small>';
                                }
                                return title;
                            }
                        },
                        callbacks: {
                            open: function () {
                                var swiper = new Swiper('.mfp-container', {
                                });
                            }
                        }
                    });
                });
            },

            initAlbumsGallery: function () {
                $('.albums__gallery').each(function (index, element) {
                    var imgs = $(this).find('.photo-thumb');
                    var items = [];
                    for (var i = 0; i < imgs.length; i++) { items.push({ src: imgs[i].href, title: imgs[i].title + ' - ' + $(imgs[i]).attr('data-credit') }); }
                    $(element).magnificPopup({
                        type: 'image',
                        mainClass: 'mfp-pictures',
                        items: items,
                        fixedContentPos: true,
                        gallery: {
                            enabled: true,
                            navigateByImgClick: true,
                            preload: [0, 1],
                            tCounter: ''
                        },
                        callbacks: {
                            open: function () {
                                var swiper = new Swiper('.mfp-content', {
                                });
                            }
                        }
                    });
                });
            },

            initVideosSkipper: function () {
                $('.video-thumb').magnificPopup({
                    //disableOn: 700,
                    type: 'iframe',
                    mainClass: 'mfp-img-mobile',
                    removalDelay: 160,
                    preloader: false,
                    fixedContentPos: true
                });
            },
        },

        videos: {

            P_PLAYER: null,
            P_IFRAME: null,
            P_TITLE: null,
            P_LEGEND: null,
            P_TIME: null,
            P_DATE: null,
            P_WRAPPER: null,
            P_HEIGHT: null,

            initPlaylist: function () {
                var links = $('.video__list').find('li');
                var mainPlayer = $('#main-video-player');
                // Create clone, to destroy main player later to avoid this one playin in background
                var clonePlayer = mainPlayer.clone();
                this.P_PLAYER = clonePlayer;
                this.P_IFRAME = clonePlayer.find('iframe');
                this.P_TITLE = clonePlayer.find('.headline__title');
                this.P_LEGEND = clonePlayer.find('.headline__legend');
                this.P_TIME = clonePlayer.find('.headline__time span');
                this.P_DATE = clonePlayer.find('.headline__dateview');
                this.P_WRAPPER = $('.video-player__wrapper');
                this.P_HEIGHT = this.P_WRAPPER.height();
                this.setList(links);

                // Load more videos
                var $container = $('.videos-index').infiniteScroll({
                    path: function () {
                        var paginate = $('.pagination');
                        return paginate.data('index') + '/' + paginate.data('page') + '?n=' + paginate.data('ts');
                    },
                    append: '.video__list',
                    history: false,
                    checkLastPage: '.pagination',
                    button: '.view-more-button',
                    scrollThreshold: false,
                    status: '.page-load-status',
                });

                // Update page number
                $container.on('load.infiniteScroll', function (event, response) {
                    $('.pagination').data('page', parseInt($('.pagination').data('page') + 1));
                });

                // Retrieve thumbs appended and add to list
                $container.on('append.infiniteScroll', function (event, response, path, items) {
                    var $links = $('.video__list').find('li');
                    App.videos.setList($links);
                });

                App.initFilter();
            },

            setList: function (links) {
                links.on('click', function (e) {
                    e.preventDefault();
                    App.videos.P_IFRAME.attr('src', $(this).attr('data-embed'));
                    App.videos.P_TITLE.html($(this).attr('data-title'));
                    App.videos.P_LEGEND.html($(this).attr('data-legend'));
                    App.videos.P_TIME.html($(this).attr('data-time'));
                    App.videos.P_DATE.html($(this).attr('data-date'));
                    links.removeClass('m--current');
                    $(this).addClass('m--current');
                    App.videos.openPlayer($(this));
                });
            },

            openPlayer: function (clickedVideo) {
                var wp = App.getViewPort();
                var _h = App.videos.P_HEIGHT;
                var scrolltop = wp == 'desktop' ? (_h * 1.5) : wp == 'tablet' ? (_h * 1.5) : (_h * 1.5);
                App.videos.P_WRAPPER.remove();
                $('#inserted').remove();
                var insertedPlayer = clickedVideo.parent().before('<div id="inserted" class="player-otf"><div class="player-otf_close">X</div><div class="sv-g l-grid-big">' + App.videos.P_PLAYER[0].innerHTML + '</div></div>');
                App.scroll(insertedPlayer, scrolltop);
                $('.player-otf_close').on('click', function (e) {
                    $(this).parent().remove();
                    //videoWrap.css('display','block');
                });
            }
        },

        initAudioPlayer: function () {
            var $audioRow = $('.audios-index__row');
            var $audioPlayer = $('.audios-index__player');
            $(function () {
                $('.audioplayer__audio').audioPlayer({
                    classPrefix: 'audioplayer', // default value: 'audioplayer'
                    strPlay: 'Play', // default value: 'Play'
                    strPause: 'Pause', // default value: 'Pause'
                    strVolume: 'Volume', // default value: 'Volume'
                });
            });

            function initPlayers($list) {

                $list.each(function () {
                    var $me = $(this);
                    var $audioSrc = $me.data('src');
                    var $audioTrigger = $me.find('.audios-index__btn');

                    $audioTrigger.on('click', function () {
                        var $audioClose = $audioPlayer.find('.audios-index__close');
                        var $audioPlayBtn = $audioPlayer.find('.audioplayer-playpause');
                        var $audioRealPlayer = $audioPlayer.find('.audioplayer__audio');
                        var $audioBar = $audioPlayer.find('.audioplayer-bar').css('width');
                        var $audioBarFull = $audioPlayer.find('.audioplayer-bar-played').css('width');
                        var $audioPlayPause = $audioPlayer.find('.audioplayer-playpause').attr('title');
                        $audioRealPlayer.attr('src', $audioSrc);
                        if ($audioPlayer.hasClass('active')) {
                            $audioPlayBtn.click();
                            setTimeout(function () {
                                if ($audioBarFull != $audioBar && $audioPlayPause != "Play") {
                                    $audioPlayBtn.click();
                                }
                            }, 500);
                        } else {
                            $audioPlayer.addClass('active');
                            $audioPlayBtn.click();
                        }
                        $audioClose.on('click', function () {
                            $audioPlayer.removeClass('active');
                            $audioPlayBtn.click();
                            $audioRealPlayer.attr('src', '');
                        });
                    });
                });
            }

            initPlayers($audioRow);

            $('.audios-index').infiniteScroll({
                // options
                path: '.pagination__next',
                append: '.audios-index__section',
                history: false,
                hideNav: '.pagination',
                status: '.page-load-status'
            });

            $('.audios-index').on('append.infiniteScroll', function (event, response, path, items) {
                $(items).each(function () {
                    $audioRow = $(this).find('.audios-index__row');
                    initPlayers($audioRow);
                });
            });

            App.initFilter();
        },

        initAudioSkipper: function () {
            var $audioPads = $('.audios-skipper__pad');
            var $audioPlayer = $('.audios-index__player');
            $(function () {
                $('.audioplayer__audio').audioPlayer({
                    classPrefix: 'audioplayer', // default value: 'audioplayer'
                    strPlay: 'Play', // default value: 'Play'
                    strPause: 'Pause', // default value: 'Pause'
                    strVolume: 'Volume', // default value: 'Volume'
                });
            });

            $audioPads.on('click', function (e) {
                e.preventDefault();
                var $audioSrc = $(this).attr('href');
                var $audioClose = $audioPlayer.find('.audios-index__close');
                var $audioPlayBtn = $audioPlayer.find('.audioplayer-playpause');
                var $audioRealPlayer = $audioPlayer.find('.audioplayer__audio');
                var $audioBar = $audioPlayer.find('.audioplayer-bar').css('width');
                var $audioBarFull = $audioPlayer.find('.audioplayer-bar-played').css('width');
                var $audioPlayPause = $audioPlayer.find('.audioplayer-playpause').attr('title');
                $audioRealPlayer.attr('src', $audioSrc);
                if ($audioPlayer.hasClass('active')) {
                    $audioPlayBtn.click();
                    setTimeout(function () {
                        if ($audioBarFull != $audioBar && $audioPlayPause != "Play") {
                            $audioPlayBtn.click();
                        }
                    }, 500);
                } else {
                    $audioPlayer.addClass('active');
                    $audioPlayBtn.click();
                }
                $audioClose.on('click', function () {
                    $audioPlayer.removeClass('active');
                    $audioPlayBtn.click();
                    $audioRealPlayer.attr('src', '');
                });
            });
        },

        news: {
            initLatestNews: function () {
                $('.latest-news__index').infiniteScroll({
                    // options
                    path: '.pagination__next',
                    append: '.latest-news__section',
                    history: false,
                    hideNav: '.pagination',
                    status: '.page-load-status'
                });

                App.initFilter();
            }
        },

        initFilter: function () {

            // Video Selector to filter by playlist (essentially by skippers)
            var $audioSelect = $('.onsubmit-audios');
            $audioSelect.on('change', function (e) {
                var $a_baseUrl = $audioSelect.data('uri');
                if (this.value == 'podcasts') $a_baseUrl += '/podcasts';
                else if (this.value != '') $a_baseUrl += '/skippers/' + this.value;
                window.location.href = $a_baseUrl;
                e.stopPropagation();
            });

            // Audio Selector to filter by skippers
            var $playlistSelect = $('.onsubmit-video-playlist');
            $playlistSelect.on('change', function (e) {
                var $v_baseUrl = $playlistSelect.data('uri');
                if (this.value != '') $v_baseUrl += '/playlist/' + this.value;
                window.location.href = $v_baseUrl;
            });

            // Selector to filter news by them
            var $filterSelect = $('.onsubmit-newstype');
            $filterSelect.on('change', function (e) {
                var $n_baseUrl = $filterSelect.data('uri');
                if (this.value != '') $n_baseUrl += '/' + this.value;
                window.location.href = $n_baseUrl;
            });

            // Selector to filter news by them
            var $skipperSelect = $('.onsubmit-skippernews');
            $skipperSelect.on('change', function (e) {
                var $ns_baseUrl = $skipperSelect.data('uri');
                if (this.value != '') {
                    $tmp = this.value.split('_');
                    $ns_baseUrl += '/skipper/' + $tmp[0] + '/' + $tmp[1];
                }
                window.location.href = $ns_baseUrl;
            });
        },

        forms: {
            initNestedDisplays: function (config) {
                config.each(function () {
                    var toggler = $(this.toggler),
                        togglerType = this.togglerType,
                        toDisplay = $(this.to_display),
                        allowedValues = this.values,
                        functionToCall;

                    switch (togglerType) {
                        case 'radio':
                            functionToCall = controlRadio.bind(undefined, toggler, toDisplay);
                            break;
                        case 'checkbox':
                            functionToCall = controlCheckbox.bind(undefined, toggler, allowedValues, toDisplay);
                            break;
                        default:
                            functionToCall = controlDefault.bind(undefined, toggler, allowedValues, toDisplay);
                            break;
                    }

                    functionToCall();

                    toggler.on('change keyup', function () {
                        functionToCall();
                    });
                });

                function controlRadio(toggler, toDisplay) {
                    if (toggler.filter(':checked').val() === '1') {
                        showElement(toDisplay);
                    } else {
                        hideElement(toDisplay);
                    }
                }

                function controlCheckbox(toggler, allowedValues, toDisplay) {
                    var isOk = false;

                    if (allowedValues === 'notchecked' && toggler.length === 1 && !toggler.is(':checked')) {
                        isOk = true;
                    } else {
                        toggler.filter(':checked').each(function () {
                            if ($.inArray($(this).val(), allowedValues) !== -1) {
                                isOk = true;
                            }
                        });
                    }

                    if (isOk) {
                        showElement(toDisplay);
                    } else {
                        hideElement(toDisplay);
                    }
                }

                function controlDefault(toggler, allowedValues, toDisplay) {
                    if ($.inArray(toggler.val(), allowedValues) !== -1) {
                        showElement(toDisplay);
                    } else {
                        hideElement(toDisplay);
                    }
                }

                function showElement(toDisplay) {
                    toDisplay.slideDown();
                }

                function hideElement(toDisplay) {
                    toDisplay.slideUp();
                }
            },

            initNestedSelects: function (config) {
                config.each(function () {
                    var selectFrom = $(this.from),
                        selectTo = $(this.to),
                        options = this.options;

                    refreshSelectTo(selectTo, options[selectFrom.val()]);

                    selectFrom.on('change', function () {
                        refreshSelectTo(selectTo, options[selectFrom.val()]);
                    });
                });

                function refreshSelectTo(selectTo, options) {
                    selectTo.find('option[value!=""]').remove();

                    for (var k in options) {
                        selectTo.append('<option value="' + k + '">' + options[k] + '</option>');
                    }
                }
            },

            initQuickNewsletterSubscription: function () {
                $('.qnl_form').on('submit', function (e) {
                    e.preventDefault();

                    var $form = $(this);

                    $.ajax({
                        type: 'POST',
                        data: $form.serialize(),
                        url: $form.attr('action'),
                        dataType: 'json',
                        success: function (data) {
                            if (data.cleanInput === true)
                                $form[0].reset();

                            $form.find('.messages').remove();
                            var message = $('<div class="messages"><p class="messages__item m--' + data.status + '">' + data.message + '</p></div>');
                            $form.prepend(message);

                            setTimeout(function () { message.remove(); }, 3000);
                        }
                    });
                });
            },

            initShowPass: function () {
                var inputs = $("[type=password]");
                inputs.each(function (i, el) {
                    var parent = $(this).parent('.form__fields__item');
                    parent.addClass('ispassword');
                    var eye = parent.find('.show-password-input');
                    eye.css({ 'left': $(el).width() + 16, 'top': $(el).position().top + 12 }).addClass('init');
                    eye.on('click', function (e) {
                        if (!eye.hasClass('show')) { eye.addClass('show'); $(el).prop({ type: "text" }); }
                        else { eye.removeClass('show'); $(el).prop({ type: "password" }); }
                    });
                });
            },
        },

        /*
         * Program village grid
         */
        initDaysSchedule: function () {
            var progCarousel = $('.days-schedule__dates');
            var currentDate = new Date();
            var currentDateTrigger = $('[href="#' + currentDate.toISOString().substring(0, 10) + '"]').last();
            var mustResizeItems = false;
            var currentItems;

            $(window).on('resize', resizeDayHeight);
            $('.days-schedule__date').on('click', showTab);
            $('.days-schedule__date:first-child').trigger('click');

            enquire.register('screen and (min-width: 40em)', {
                match: function () {
                    mustResizeItems = true;
                    $(window).on('resize', resizeItems);
                },
                unmatch: function () {
                    currentItems.css('height', '');
                    mustResizeItems = false;
                    $(window).off('resize', resizeItems);
                }
            });

            progCarousel.find('.carousel__view').on('init', function (e, s) {
                s.goTo($('.days-schedule__date').index(currentDateTrigger));
                currentDateTrigger.trigger('click');
            }).slick({
                autoplay: false,
                slide: '.days-schedule__date',
                slidesToShow: 7,
                slidesToScroll: 7,
                arrows: true,
                appendArrows: progCarousel.find('.carousel__controls'),
                prevArrow: progCarousel.find('.m--prev'),
                nextArrow: progCarousel.find('.m--next'),
                infinite: false,
                mobileFisrt: true,
                responsive: [{
                    breakpoint: 640,
                    settings: { slidesToShow: 2, slidesToScroll: 2 }
                }, {
                    breakpoint: 768,
                    settings: { slidesToShow: 4, slidesToScroll: 4 }
                }, {
                    breakpoint: 960,
                    settings: { slidesToShow: 5, slidesToScroll: 5 }
                }, {
                    breakpoint: 1280,
                    settings: { slidesToShow: 7, slidesToScroll: 7 }
                }]
            });

            function resizeItems() {
                currentItems = $('.days-schedule__day.is-current .days-schedule__item');

                currentItems.css('height', '');

                currentItems.height(Math.max.apply(null, currentItems.map(function () {
                    return $(this).height();
                }).get()));
            }

            function resizeDayHeight() {
                $('.days-schedule__days').css('height', $('.days-schedule__day.is-current').outerHeight());
            }

            function showTab() {
                var target = $($(this).attr('href'));

                $('.days-schedule__date.is-current, .days-schedule__day.is-current').removeClass('is-current');
                target.addClass('is-current');
                $(this).addClass('is-current');
                if (mustResizeItems) {
                    resizeItems();
                }
                resizeDayHeight();
            }
        },

        skipperArea: {

            initForms: function () {
                $('.skiparea-registration__form .form__submit').on('click', App.skipperArea.validateForm);

                $('#contact_se_same_tm').on('click', function () {
                    $(this).parent().siblings('li').toggle(!$(this).prop('checked'));
                });

                $('.js-list').each(function () {
                    var $list = $(this);
                    var $template = $list.find('.js-template').clone().removeClass('js-template');
                    var $btn = $list.next('.js-add-to-list');

                    $template.find('.form__fieldset__title').text($template.find('.form__fieldset__title').data('new-item-text'));

                    $btn.on('click', function (e) {
                        e.preventDefault();
                        $template.clone().appendTo($list);
                    });

                    $list.on('click', '.js-remove-from-list', function () {
                        $(this).parents('.js-item').remove();
                    });
                });
            },

            initAddSponsor: function () {
                var btn = $('#skiparea_add_sponsor'),
                    target = $('#' + btn.attr('data-target'));

                btn.on('click', function (e) {
                    var data = {
                        applicant_id: $(target.find('form input[name="applicant_id"]')[0]).attr('value'),
                        k: $(target.find('form.m--sponsor')).length + 1
                    };

                    $.ajax({
                        url: btn.attr('data-url'),
                        method: 'POST',
                        data: data,
                        success: function (html) {
                            var insert = $(html);
                            target.append(insert);

                            insert.find('input[type="submit"]').on('click', App.skipperArea.validateForm);
                        }
                    });
                });
            },

            validateForm: function (e) {
                e.preventDefault();

                var submit = $(this),
                    form = submit.closest('form'),
                    loader = $(App.config.loaderMarkup);

                submit.hide().after(loader);
                $(form.find('.messages')).detach();
                $(form.find('.m--error')).removeClass('m--error');

                $.ajax({
                    url: submit.attr('data-validation'),
                    type: 'POST',
                    data: form.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        if (data.success === false) {
                            if (data.error_message === undefined) {
                                data.error_message = "Une erreur s'est produite, merci de contacter l'administrateur.";
                            }

                            if (data.error_message) {
                                var errorString;
                                if (Array.isArray(data.error_message)) {
                                    errorString = '<ul class="messages l-unlist">';
                                    data.error_message.forEach(function (message) {
                                        errorString += '<li class="messages__item m--error">' + message + '</li>';
                                    });
                                    errorString += '</ul>';
                                } else {
                                    errorString = '<ul class="messages l-unlist"><li class="messages__item m--error">' + data.error_message + '</li></ul>';
                                }

                                var errorElement = $(errorString);

                                $(form.find('fieldset, .form__fieldset__title')[0]).before(errorElement);
                                App.scroll(errorElement);
                            }

                            if (Array.isArray(data.errors)) {
                                data.errors.forEach(function (id) {
                                    if (id)
                                        $('#' + id).closest('.form__fields__item').addClass('m--error');
                                });
                            }

                            loader.detach();
                            submit.show();
                        } else {
                            var successElement = $('<ul class="messages l-unlist"><li class="messages__item m--valid">' + data.success_message + '</li></ul>');

                            $(form.find('fieldset, .form__fieldset__title')[0]).before(successElement);
                            App.scroll(successElement);

                            if (data.sponsor_id) form.find('input[name=sponsor_id]').val(data.sponsor_id);

                            loader.detach();
                            submit.show();
                        }
                    }
                });
            }
        },

        // === DZ UPLOAD ===
        dropZone: function () {

            Dropzone.autoDiscover = false;
            // Get the default template HTML and remove it from the document
            $('.dz-uploader').each(function (index, element) {
                var previewNode = this.querySelector('.dz-template');
                previewNode.id = "";
                var previewTemplate = previewNode.parentNode.innerHTML;
                previewNode.parentNode.removeChild(previewNode);

                var myDropzone = new Dropzone('#' + this.id, {
                    url: $(element).attr('data-url'),
                    maxFiles: ($(element).attr('data-mode') == 'multi') ? 20 : 1,
                    thumbnailWidth: 66,
                    thumbnailHeight: 66,
                    parallelUploads: 20,
                    maxFilesize: 800,
                    timeout: 180 * 1000,
                    previewTemplate: previewTemplate,
                    autoQueue: true, // Make sure the files aren't queued until manually added
                    autoProcessQueue: true,
                    previewsContainer: this.querySelector('.dz-files-preview'), // Define the container to display the previews
                    clickable: this.querySelector('.fileinput-button'), // Define the element that should be used as click trigger to select files.
                    createImageThumbnails: true,
                    //hiddenInputContainer: 'files'
                });

                myDropzone.on("success", function (file, responseText) {
                    var inputName = $(element).attr('data-target');
                    // Handle the responseText here. (In case of errors from upload)
                    var txt = JSON.parse(responseText);
                    var errmsg = file.previewTemplate.querySelector('.dz-messages');
                    $(errmsg).append('<span class="dz-upload-error">' + txt.error + '</span>');
                    if (txt.error === '') {
                        var inputContainer = file.previewTemplate.querySelector('.dz-preview-actions');
                        $(inputContainer).append('<input type="hidden" name="' + inputName + '" value="' + txt.file_name + '">');
                    }
                });

                myDropzone.on("error", function (file, errorMessage) {
                    console.log(errorMessage);
                });

            });
        },

        rankingsToggle: function () {
            var $rankingsItem = $('.rankings__item');
            var $rankingsBtn = $rankingsItem.find('.rankings__toggler');

            $rankingsBtn.on('click', function (e) {
                e.preventDefault();
                $(this).closest($rankingsItem).toggleClass('active');
                $(this).closest($rankingsItem).siblings().removeClass('active');
            });

            /*$('.rankings__toggler').on('click', function(e) {
                e.preventDefault();
                console.log($(this).parent().parent());
                $(this).parent().parent().toggleClass('active');
            });*/

            var $rankingsSelect = $('.onsubmit-rank');
            var $baseUrl = $rankingsSelect.data('uri');
            $rankingsSelect.on('change', function (e) {
                if (this.value != '') $baseUrl += '/' + this.value;
                window.location.href = $baseUrl;
            });
        },

        rankingsGetInfo: function () {
            var $rankingsInfo = $('.rankings__info');

            $rankingsInfo.click(function (e) {
                e.preventDefault();
                $rankingsInfo.each(function (id, el) {
                    if (el.id !== e.target.id) $(this).removeClass('active');
                });
                $(this).toggleClass('active');
            });

            $('.bonus').on('click', function (e) {
                e.preventDefault();
                $(this).toggleClass('active');
            });
        },

        rankingsRound: function () {
            var $rankingsRound = $('.rankings__svg');
            var $rankingsContent = null;

            function rankingsRoundDesk() {
                setTimeout(function () {
                    $rankingsRound.each(function () {
                        $(this).addClass('active');
                        $(this).trigger('triggersvg');
                    });
                }, 1000);
            }

            function rankingsRoundMobile() {
                document.documentElement.style.setProperty('--app-width', $(window).width() + 'px');
                $('.rankings__toggler').click(function () {
                    if ($('.rankings__list').length)
                        $rankingsContent = $(this).closest('.rankings__list').next();
                    else
                        $rankingsContent = $(this).closest('.rankings__item');
                    var $rankingsSVG = $rankingsContent.find('.rankings__svg');
                    $rankingsSVG.addClass('active');
                    $rankingsSVG.trigger('triggersvg');
                });
            }

            $rankingsRound.each(function () {
                var $roundRadius = $(this).find('circle').attr('r');
                var $roundPercent = $(this).data('percent');
                var $roundCircum = 2 * $roundRadius * Math.PI;
                var $roundDraw = $roundPercent * $roundCircum / 100;

                $(this).on('triggersvg', function () {
                    if ($(this).hasClass('active')) {
                        $(this).css('stroke-dasharray', ($roundDraw) + ' 999');
                    } else {
                        $(this).css('stroke-dasharray', 252 + ' 999');
                    }
                });

            });

            if ($(window).width() >= 1024) {
                rankingsRoundDesk();
            } else {
                rankingsRoundMobile();

            }

            $(window).resize(function () {
                if ($(window).width() >= 1024) {
                    rankingsRoundDesk();
                } else {
                    rankingsRoundMobile();
                }
            });

        },

        statsBar: function () {
            var $statsBar = $('.stats__bar');

            setTimeout(function () {
                $statsBar.each(function () {
                    var $statsValue = $(this).data('percent');
                    var $statsInner = $(this).find('.stats__bar-inner');
                    var $statsDot = $(this).find('.stats__bar-dot');
                    $statsInner.css("background-size", $statsValue + "% 4px");
                    $statsDot.css("left", ($statsValue + "%"));
                });
            }, 1000);

        },

        indexFiltersToggle: function () {
            var $indexBtn = $('.index-filters__btn');

            $indexBtn.click(function () {
                $(this).parent().toggleClass('active');
            });

        },

        getViewPort: function () {
            var ww = $(window).width();
            if (ww > 960) return 'desktop';
            else return ww > 480 ? 'tablet' : 'mobile';
        }

    };
    // ---------- APP END

    // Init app
    $(document).ready(App.init);
    // $(window).load(App.load); // jQuery 2
    $(window).on('load', App.load); // jQuery 3
    $(window).on('hashchange', App.initHash);

    // window.App = App;

    //Tarteaucitron
    App.initTAC();

})(jQuery);
