(function ($) {

    // ---------- Header START
    $.widget('vg.header', {

        _create: function () {
            this._responsiveStuff();
        },

        _responsiveStuff: function () {

            var $winHeight = $(window).height();
            var $stickyNav = $('.header__inner');
            var $scrollBar = $('.scrollbar__sticky');
            var $scrollBarAccess = $('.scrollbar__access');
            var $lang = $('.lang');
            var $menuBtn = $('.btn.m--menu');
            var $menuBtnClose = $('.btn.m--close');
            var $menuBar = $('.menu__bar');
            var $menuItems = $('.header__item');
            var $clone1 = ($scrollBarAccess).clone().addClass('m--mobile');
            var $clone3 = $('.menu__item.m--skippers').find('.submenu__list').clone();
            var $clone4 = $('.menu__item.m--course .submenu').clone();
            var $menuSkipper = $('.skipmenu');
            var $menuSkipperBtn = $('.btn.m--skippers');
            var $menuCourse = $('.racemenu');
            var $menuCourseBtn = $('.btn.m--course');

            function prependMobile() {
                $('.header__espaces').prepend($clone1);
                $('.skipmenu').prepend($clone3);
                $('.racemenu').prepend($clone4);
            }

            function prependDesk() {
                $clone1.remove();
                $clone3.remove();
                $clone4.remove();
            }

            function menuMobile() {
                $menuSkipperBtn.click(function () {
                    $menuSkipper.toggleClass('active');
                });
                $menuCourseBtn.click(function () {
                    $menuCourse.toggleClass('active');
                });
                $menuBtn.click(function () {
                    $menuBar.addClass('active');
                    $("#header").addClass('active');
                    $(".menu").addClass('active');
                    $(".main").addClass('blurried');
                    $(".footer").addClass('blurried');
                });
                $menuBtnClose.click(function () {
                    $menuBar.removeClass('active');
                    $("#header").removeClass('active');
                    $(".menu").removeClass('active');
                    $(".main").removeClass('blurried');
                    $(".footer").removeClass('blurried');
                });
                $menuItems.each(function () {
                    $(this).on("click", function () {
                        $(this).siblings().removeClass('active');
                        $(this).toggleClass('active');
                    });
                });
            }

            function menuDesk() {
                if ($menuBar.hasClass('active')) {
                    $menuBar.removeClass('active');
                    $(".menu").removeClass('active');
                    $(".main").removeClass('blurried');
                    $(".footer").removeClass('blurried');
                }
            }

            function stickyMobile() {
                $scrollBar.addClass("m--sticky");
                $(window).scroll(function () {
                    if (!$('.scrollbar__sticky').hasClass('m--sticky')) {
                        $scrollBar.addClass("m--sticky");
                    }
                });
            }

            function stickyDesk() {
                // if ($scrollBar.hasClass("m--sticky")) {
                //   $scrollBar.removeClass("m--sticky");
                // }
                // $(window).scroll(function() {
                //   if ($(this).scrollTop() > $winHeight){
                //     $stickyNav.addClass("m--sticky");
                //     $('.main').css("margin-top", "155px");
                //     $scrollBar.addClass("m--sticky");
                //   }
                //   else if ($(this).scrollTop() > 155 && $(this).scrollTop() < $winHeight){
                //     $stickyNav.addClass("m--invisible");
                //   }
                //   else {
                //     $stickyNav.removeClass("m--invisible");
                //     $stickyNav.removeClass("m--sticky");
                //     $scrollBar.removeClass("m--sticky");
                //     $('.main').css("margin-top", "0");
                //   }
                // });
            }

            // function imgDesk() {
            //   var $firstList = $(".submenu__list.m--partners");
            //   var $secondList = $(".submenu__list.m--skippers");
            //   $secondList.find("li a").each(function() {
            //     $secondListImg = $(this).find("img").attr("src");
            //     $(this).mouseenter(function() {
            //       $firstList.css("background-image", "url(" + $secondListImg +")");
            //     });
            //   });
            // }

            function skippersToggle() {
                var $skippersMenu = $(".menu__item.m--skippers");
                var $skippersLink = $(".view-skippers-all");
                $skippersLink.click(function () {
                    $skippersMenu.addClass("active");
                });
                $skippersMenu.click(function () {
                    $(this).removeClass("active");
                });
            }

            function onEventMenu() {
                if ($(window).width() < 960) {
                    menuMobile();
                    stickyMobile();
                    prependMobile();
                } else {
                    menuDesk();
                    stickyDesk();
                    prependDesk();
                    // imgDesk();
                    skippersToggle();
                }
            }

            $(window).resize(function () {
                onEventMenu();
            });

            onEventMenu();
        },

    });
    // ---------- Header END

})(jQuery);
