(function(){

// MAGNIFIC POPUP
$.extend(true, $.magnificPopup.defaults, {
    // tClose: 'Close (Esc)', // Alt text on close button
		// closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg role="img" class="svg-icon"><use xlink:href="/public/images/web1/pictos/misc.sprite.svg#close"></use></svg></button>',
    /*gallery: {
			//     tPrev: 'Previous (Left arrow key)', // Alt text on left arrow
			//     tNext: 'Next (Right arrow key)', // Alt text on right arrow
			tCounter: '', // Markup for "1 of 7" counter
			arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><svg role="img" class="svg-icon mfp-prevent-close"><use xlink:href="/public/images/web1/pictos/misc.sprite.svg#chevron-%dir%" class="mfp-prevent-close"></use></svg></button>',
		},
		image: {
		//     tError: '<a href="%url%">The image</a> could not be loaded.' // Error message when image could not be loaded
			titleSrc: function(item){
				var copyright = item.el.attr('data-copyright');
				var title = item.el.attr('title');
	
				if(copyright){
					title += '<small>' + copyright + '</small>';
				}
	
				return title;
			}
		},*/
    // image: {
    //     tError: '<a href="%url%">The image</a> could not be loaded.' // Error message when image could not be loaded
    // },
    // ajax: {
    //     tError: '<a href="%url%">The content</a> could not be loaded.' // Error message when ajax request failed
    // }
});

})();
